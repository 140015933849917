@keyframes bubble-anim-clicked {
  0% {
    visibility: visible;
    transform: scale(1);
    background-color: white;
    color: white;
  }

  97% {
    background-color: white;
    color: white;
  }

  100% {
    visibility: visible;
    transform: scale(1.5);
    background-color: #06407f;
    color: #06407f;
  }
}

@keyframes bubble-anim-idle {
  0% {
    transform: scaleX(1) scaleY(1);
  }
  33% {
    transform: scaleX(1.02) scaleY(0.98);
  }
  66% {
    transform: scaleX(0.98) scaleY(1.02);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}

.preview {
  display: flex;
  height: 100%;

  .preview-inner {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;

    .fill {
      height: 500px;
      width: 100%;
      max-width: 1200px;
    }

    .bubble {
      position: relative;
      width: 100%;
      height: 100%;

      .bubble-inner:nth-child(1) {
        left: calc(50%);
        top: 100px;

        a {
          max-width: 150px;
          animation: bubble-anim-idle 4.00s ease-in-out infinite;
        }
      }

      .bubble-inner:nth-child(2) {
        left: calc(70%);
        top: 350px;

        a {
          max-width: 160px;
          animation: bubble-anim-idle 3.50s ease-in-out infinite;
        }
      }

      .bubble-inner:nth-child(3) {
        left: calc(10%);
        top: 350px;

        a {
          max-width: 160px;
          animation: bubble-anim-idle 3.75s ease-in-out infinite;
        }
      }

      .bubble-inner:nth-child(4) {
        left: calc(30%);
        top: 450px;

        a {
          max-width: 140px;
          animation: bubble-anim-idle 4.00s ease-in-out infinite;
        }
      }

      .bubble-inner:nth-child(5) {
        left: calc(25%);
        top: 150px;

        a {
          max-width: 100px;
          animation: bubble-anim-idle 4.10s ease-in-out infinite;
        }
      }

      .bubble-inner:nth-child(6) {
        left: calc(40%);
        top: 290px;

        a {
          max-width: 100px;
          animation: bubble-anim-idle 3.90s ease-in-out infinite;
        }
      }

      .bubble-inner {
        position: absolute;

        .clicked {
          background-color: #06407f;
          color: #06407f;
          animation: bubble-anim-clicked 0.05s linear !important;
          visibility: hidden;
        }

        a {
          display: block;
          position: relative;
          width: 100%;
          max-width: 200px;
          background-color: white;
          border-radius: 1000px;
          text-decoration: none;
          font-weight: bold;
          color: black;

          &:hover {
            cursor: pointer;
          }

          svg {
            width: 100%;
            height: auto;
          }
        }

        p {
          pointer-events: none;
          position: absolute;
          display: table;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          text-align: center;
        }
      }
    }
  }
}