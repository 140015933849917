@import "./src/main";

.footer{
  background-color: $main-color;
  height: 120px;
  text-align: right;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  line-height: 120px;
  margin-top: -1px;

  a {
    color: white !important;
    font-size: 17px;
    margin-right: 15px;
  }
}