$main-color: #06407f;
$line-width: 3px;
$line-radius: 10px;

@font-face {
  font-family: "SourceCodePro";
  src: local("SourceCodePro"),
  url("./fonts/source-code-pro/SourceCodePro-Medium.ttf") format("truetype");
}

@mixin small {
  @media (max-width: #{576px}) {
    @content;
  }
}

@mixin medium {
  @media (max-width: #{1200px}) and (min-width: #{577px}) {
    @content;
  }
}

body {
  max-width: 100vw;
  overflow-y: scroll;
  min-height: 100vh;
  margin: 0;
}

.accordion-content {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body, html {
  scroll-behavior: smooth !important;
  height: 100%;
}

.title {
  width: 100%;
  margin: 70px 0 70px 0;
}

html * {
  font-family: "SourceCodePro", serif !important;
}

a {
  text-decoration: underline;
  color: $main-color;
}

a:visited {
  color: $main-color;
}
