@import "src/main";

.code-container {

  pre {
    text-align: left !important;
    border-radius: $line-radius;
    border-color: $main-color;
    border-style: solid;
    border-width: $line-width;
    padding: 10px;
    tab-size: 2;
    margin-bottom: 0;
    overflow-wrap: break-word;

    code {
        overflow: auto;
        display: block;
    }
  }

  label {
    text-align: right;
    display: block;
    font-size: 15px;
    line-height: 20px;
    height: 20px;
    margin-right: 15px;
  }
}