.waves {
  position: relative;
  width: 100%;
  height: 100px;
  min-height: 100px;
  margin-bottom: -10px;
}

/* https://codepen.io/goodkatz/pens/popular */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 10s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 13s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 16s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 25s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}