@import "src/main";

.image-container {

  .test {
    width: 100%;
    text-align: center;

    .inner-text {

      margin: auto;
      display: inline-block;

      label {
        text-align: right;
        display:block;
        font-size: 15px;
        line-height: 20px;
        height: 20px;
        margin-right: 15px;
      }

      img {

        display: block; /* remove extra space below image */
        max-width: 100%;
        max-height: 100%;

        border-radius: $line-radius;
        box-sizing: border-box;
        border-color: $main-color;
        border-style: solid;
        border-width: $line-width;
      }
    }
  }
}