@import "./src/main";

.blog-entry {
  .content {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;

    p {
      word-wrap: break-word;
      color: black;
      line-height: 30px;
      text-align: justify;
      font-size: 19px;
      @include small {
        font-size: 21px;
      }
      @include medium {
        font-size: 20px;
      }

      b {
        word-wrap: break-word;
        font-size: 19px;
        @include small {
          font-size: 21px;
        }
        @include medium {
          font-size: 20px;
        }
      }
    }

    h1 {
      text-align: left;
      font-size: 35px;
    }

    h3 {
      word-wrap: break-word;
      text-align: left;
      font-size: 25px;
    }

    h5 {
      text-align: left;
      font-size: 19px;
    }

    li {
      color: black;
      text-align: left;
      line-height: 40px;
      font-size: 19px;
      @include small {
        font-size: 21px;
      }
      @include medium {
        font-size: 20px;
      }
    }

    li ~ li {
      margin-top: 5px;
    }

    p ~ ul {
      margin-top: 0;
    }

    p ~ ol {
      margin-top: 0;
    }

    hr {
      height: 2px;
      margin-top: 4px;
      margin-bottom: 4px;
      width: 100%;
      border-width: 0;
      background-color: $main-color;
    }

    table {
      text-align: left;
      border-spacing: 0;

      tr:last-child {
        td:last-child {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: $line-radius;
        }

        td:first-child {
          border-bottom-left-radius: $line-radius;
          border-bottom-right-radius: 0;
        }
      }

      tr:first-child {
        th:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: $line-radius;
        }

        th:first-child {
          border-top-left-radius: $line-radius;
          border-top-right-radius: 0;
        }
      }

      th {
        border-style: solid;
        border-color: $main-color;
        border-width: $line-width;
        padding: 5px;
      }

      td {
        padding: 5px;
        border-style: solid;
        border-color: $main-color;
        border-width: $line-width;
        border-top-width: 0;
      }

      th + th {
        border-left-width: 0;
      }

      td + td {
        border-left-width: 0;
      }

      //TODO
      //https://css-tricks.com/responsive-data-tables/
    }
  }
}