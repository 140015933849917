$width: 800px;

.MuiAccordionSummary-content {
    height: 10px !important;
    margin: 0 !important;
}

.MuiAccordionDetails-root {
    padding: 0 !important;
}

.MuiAccordionSummary-root {
    height: 10px !important;
    min-height: 10px !important;
    max-height: 10px !important;
    margin: 0 !important;
}

.MuiPaper-root {
    width: 100%;
    box-shadow: none !important;
    margin: auto !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    text-align: center !important;
    max-width: $width !important;
}