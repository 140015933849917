@import "./../../main.scss";

.navbar {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  div {
  align-items: center;
    width: 100%;
    height: 100px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    a {
      margin: 20px;
      font-size: 18px;
      text-decoration: underline;
    }

    a + a {
      margin-right: 5px;
    }

    label {
      margin-bottom: 30px;
      @include small {
        display: none;
      }
    }
  }

  .title {
    width: 100%;
    margin: 70px 0 70px 0;
  }
}