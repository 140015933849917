@import "./src/main";


.rss-feed-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  text-align: center;
  width: 200px;
  z-index: 10000;

  background-color: $main-color;
  height: 40px;
  line-height: 40px;
  border-radius: $line-radius;
  border-color: white;
  border-style: solid;
  border-width: 1px;

  a {
    color: white !important;
    margin: auto !important;
  }
}