@keyframes float {
  0% {
    transform: translatey(0px);
    scale: 1;
  }
  50% {
    transform: translatey(-30px);
    scale: 1.2;
  }
  100% {
    transform: translatey(0px);
    scale: 1;
  }
}

.blog-entry-preview {

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    align-content: center;
    max-width: 1000px;

    color: black;
    text-decoration: none;

    height: 20%;
    width: 20%;
    border-radius: 100px;

    //Jelilicent https://codepen.io/Jelilicent/pen/oNzPjEo
    background: radial-gradient(
                    circle at 75% 30%,
                    #f5f1f1 5px, //reflection pupil
                    #7f7cc4 8%, //inner bubble
                    #0022ff 60%, //button inner bubble
                    #e70202 100%
    );
    box-shadow: inset 0 0 20px #efebeb, inset 10px 0 46px #009bff, //rim //inner rim
    inset 0 50px 140px #e5e4e4, 0 0 60px #05e8e8; //glow

    &:hover {
      animation: float 2.5s ease-in-out infinite;
      cursor: pointer;
    }

    .bubble {
      padding: 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;

      .blogentry-text {
        margin-top: 10px;
        width: 100%;
        text-align: center;

        &:hover {
          cursor: pointer;
        }
      }

      .blogentry-title {
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        text-align: center;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}